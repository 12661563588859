<template>
    <div class="Project">
        <el-container>
            <el-header>Header</el-header>
            <el-container>
                <el-aside width="200px"><project-aside></project-aside></el-aside>
                <el-main>
                    <!-- <project-dropdown></project-dropdown> -->
                    <project-upload></project-upload>
                </el-main>
            </el-container>
        </el-container>
    </div>
</template>


<script>
// @ is an alias to /src
import ProjectAside from './ProjectAside.vue'
// import ProjectDropdown from './ProjectDropdown.vue'
import ProjectUpload from './ProjectUpload.vue'

export default {
  name: 'project',
  components: {
    ProjectAside,
    ProjectUpload,
    // ProjectDropdown,
  },
  // data(){

  // },
  // methods(){

  // }
}
</script>


<style>
  .el-header, .el-footer {
    background-color: #B3C0D1;
    color: #333;
    text-align: center;
    line-height: 60px;
  }
  
  .el-aside {
    background-color: #D3DCE6;
    color: #333;
    text-align: center;
    line-height: 200px;
  }
  
  .el-main {
    background-color: #E9EEF3;
    color: #333;
    text-align: center;
    line-height: 160px;
  }
  
  body > .el-container {
    margin-bottom: 40px;
  }
  
  .el-container:nth-child(5) .el-aside,
  .el-container:nth-child(6) .el-aside {
    line-height: 260px;
  }
  
  .el-container:nth-child(7) .el-aside {
    line-height: 320px;
  }
</style>