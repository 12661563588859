<template>
  <div>
    <el-tag
      :key="tag.id"
      v-for="tag in dynamicTags"
      closable
      :type="type[tag.first]"
      :disable-transitions="false"
      @close="handleClose(tag)"
      effect="dark"
    >
      {{ tag.command }}
    </el-tag>
  </div>
</template>


<script>
export default {
  props: {
    commands: {
      type: Array,
    },
  },
  data() {
    return {
      dynamicTags: this.commands,
      type: { 1: "success", 0: "info" },
    };
  },
  methods: {
    handleClose(tag) {
      //   this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$emit("delete", tag["id"]);
    },
  },
  watch: {
    commands: function (newValue) {
      this.dynamicTags = newValue;
    },
  },
};
</script>


<style>
.el-tag + .el-tag {
  margin-left: 10px;
}
.button-new-tag {
  margin-left: 10px;
  height: 32px;
  line-height: 30px;
  padding-top: 0;
  padding-bottom: 0;
}
.input-new-tag {
  width: 90px;
  margin-left: 10px;
  vertical-align: bottom;
}
</style>