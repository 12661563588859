<template>
  <div class="Collect">
    <el-container>
      <el-header
        ><h1>
          {{ projectName }}自然语言采集系统({{ sort }}/{{ total }})
        </h1></el-header
      >
      <el-main>
        <el-form
          style="width: 80%; margin: auto"
          ref="collectForm"
          label-width="130px"
          :model="collectData"
        >
          <el-form-item label="命令项">
            <el-input :readonly="true" :value="collectData.command"></el-input>
          </el-form-item>
          <el-form-item label="提示">
            <el-input
              :rows="10"
              type="textarea"
              :value="collectData.describe"
              :readonly="true"
            ></el-input>
          </el-form-item>
          <el-form-item label="示例">
            <el-input :value="collectData.example" :readonly="true"> </el-input>
          </el-form-item>
          <el-form-item label="请输入你的说法" style="line-height: 10x">
            <el-input
              :placeholder="collectData.content"
              v-model="collectData.newCommand"
              @keyup.enter.native="handelSave($event)"
            ></el-input>
            <p style="text-align: left">(支持中文汉字和部分英文固定说法，每次仅支持一条说法录入；提交—>回车，切换说法->shift+回车)</p>
          </el-form-item>

          <el-form-item>
            <el-button type="primary" @click="previousSubmit()"
              >上一个</el-button
            >
            <el-button type="primary" @click="onSubmit()">提交</el-button>
            <el-button type="primary" @click="nextSubmit()">下一个</el-button>
          </el-form-item>

          <el-form-item label="历史输入项" style="text-align: left">
            <collect-history
              :commands="collectData.history"
              @delete="delHistory"
              v-if="collectData.history"
            ></collect-history>
            <p>
              <!-- (绿色表示首次输入的说法，灰色表示已存在的说法，二者都会得分，绿色得分更高。若输入错误可删除。) -->
              (绿色表示首次输入的说法，灰色表示已存在的说法。若输入错误可删除。)
            </p>
          </el-form-item>
        </el-form>
      </el-main>
    </el-container>
  </div>
</template>

<script>
import {
  nextRecord,
  saveRecord,
  commandTotal,
  getHistory,
  deleteHistory,
} from "@/network/collect.js";

import { getProject } from "@/network/login.js";
import { getUserSort } from "@/network/collect.js";

// import router from "vue-router";
import CollectHistory from "./CollectHistory.vue";
export default {
  components: { CollectHistory },
  data() {
    return {
      collectData: {
        command: "",
        describe: "",
        newCommand: "",
        commandId: "",
        example: "",
        history: [],
        content: "",
      },
      total: 0,
      sort: 1,
      projectName: "",
      projectId: "",
    };
  },
  created() {
    this.projectId = Number(localStorage.getItem("projectId"))
      ? Number(localStorage.getItem("projectId"))
      : 0;
    this.getSort();
    this.setProjectName();
  },
  methods: {
    getSort() {
      getUserSort(this.projectId).then((res) => {
        this.sort = res.data?res.data:1
        return this.getCollectData();
      });
    },
    handelSave(e) {
      if (e.altKey == true) {
        this.nextSubmit();
      } else {
        this.onSubmit();
      }
    },
    onSubmit() {
      if (this.containsNumber(this.collectData.newCommand)) {
        saveRecord({
          newCommand: this.collectData.newCommand,
          commandId: this.collectData.commandId,
          projectId: this.projectId,
        })
          .then(
            () => {
              console.log("123");
              return getHistory({
                commandId: this.collectData.commandId,
                projectId: this.projectId,
              });
            },
            (err) => {
              this.myMessage(err.message, "warning");
              return getHistory({
                commandId: this.collectData.commandId,
                projectId: this.projectId,
              });
            }
          )
          .then((res) => {
            this.collectData.newCommand = "";
            this.collectData.history = res.data;
          });
        // .then(() => {
        //   return getHistory(this.collectData);
        // })
        // .then((res) => {
        //   this.collectData.newCommand = "";
        //   this.collectData.history = res.data;
        // })
        // .catch((error) => {
        //   this.myMessage(error.msg, "warning");
        // });
      }
    },

    nextSubmit() {
      if (this.collectData.newCommand != "") {
        this.myMessage("请点击提交按钮，提交当前输入的说法哦", "warning");
        return "";
      }
      this.collectData.history = "";
      this.sort = this.sort + 1;
      if (this.sort > this.total) {
        this.sort = this.sort - 1;
        this.myMessage("到结尾了哦", "warning");
      }
      this.next();
    },

    previousSubmit() {
      if (this.collectData.newCommand != "") {
        this.myMessage("请点击提交按钮，提交当前输入的说法哦", "warning");
        return "";
      }
      this.collectData.history = "";
      this.sort = this.sort - 1;
      if (this.sort <= 0) {
        this.sort = this.sort + 1;
        this.myMessage("前边没有了哦", "warning");
      }
      this.next();
    },

    getCollectData() {
      this.next();
      commandTotal(this.projectId).then((res) => {
        this.total = res.data;
      });
    },

    next() {
      nextRecord({
        projectId: this.projectId,
        sort: this.sort,
      })
        .then((res) => {
          this.collectData.command = res.data.command;
          this.collectData.describe =
            res.data.scene + "\n\n" + res.data.commandDescribe;
          this.collectData.commandId = res.data.id;
          this.collectData.example =
            res.data.content == ""
              ? res.data.example
              : res.data.example +
                "(新说法必须包含关键字:" +
                res.data.content +
                ")";
          this.collectData.newCommand = "";
          this.collectData.content =
            res.data.content == ""
              ? "请输入新说法"
              : "新说法必须包含关键字:" + res.data.content;
          return getHistory({
            commandId: this.collectData.commandId,
            projectId: this.projectId,
          });
        })
        .then((res) => {
          this.collectData.history = res.data;
        })
        .catch((error) => {
          this.myMessage(error.msg, "warning");
        });
    },
    myMessage(message, type) {
      this.$message({
        message,
        type,
      });
    },

    delHistory(recordId) {
      deleteHistory(recordId)
        .then(() => {
          return getHistory({
            commandId: this.collectData.commandId,
            projectId: this.projectId,
          });
        })
        .then((res) => {
          this.collectData.history = res.data;
        });
    },
    setProjectName() {
      getProject(this.projectId).then((res) => {
        this.projectName = res.data.projectName;
      });
    },

    containsNumber(str) {
      // var reg = /[\u4E00-\u9FA5]|,|\?|，|？/g;
      var reg = /[\u4E00-\u9FA5PMTVOCwifi]/g;
      if (str == "") {
        this.myMessage("请输入新说法", "warning");
        return false;
      }
      var result = str.match(reg);
      if (result == null) {
        this.myMessage("支持中文汉字和部分英文固定说法", "warning");
        return false;
      }
      var reg_len = result.length;
      if (str.length != reg_len) {
        this.myMessage("支持中文汉字和部分英文固定说法", "warning");
        return false;
      }
      return true;
    },
  },
};
</script>


<style>
.el-header,
.el-footer,
.el-divider {
  background-color: #b3c0d1;
  color: #333;
  text-align: center;
  line-height: 22px;
}

.el-aside {
  background-color: #d3dce6;
  color: #333;
  text-align: center;
  line-height: 200px;
}

.el-main {
  background-color: #e9eef3;
  color: #333;
  text-align: center;
  line-height: 150px;
}

body > .el-container {
  margin-bottom: 40px;
}

.el-container:nth-child(5) .el-aside,
.el-container:nth-child(6) .el-aside {
  line-height: 260px;
}

.el-container:nth-child(7) .el-aside {
  line-height: 320px;
}
</style>