import request from './request'


export function login(data) {
    return request({
        url: '/user/login?userName=' + data,
        method: 'post',
    })
}

export function getProject(params) {
    return request({
        url: '/project/info?projectId=' + params,
        method: 'get',
        params: params,
    })
}

export function getProjectList() {
    return request({
        url: '/project/list',
        method: 'get',
    })
}