import request from './request'


export function nextRecord(data) {
    return request({
        url: '/collect/next',
        method: 'post',
        data: data,
    })
}


export function saveRecord(data) {
    return request({
        url: '/collect/save',
        method: 'post',
        data: data,
    })
}

export function commandTotal(data) {
    console.log(data);
    return request({
        url: '/collect/total?projectId=' + data,
        method: 'get',
    })
}

export function getHistory(data) {
    return request({
        url: '/collect/info',
        method: 'post',
        data: data,
    })
}

export function deleteHistory(data) {
    console.log(data);
    return request({
        url: '/collect/delete?recordId=' + data,
        method: 'post',
    })
}

export function getUserSort(data) {
    console.log(data);
    return request({
        url: '/collect/sort?projectId=' + data,
        method: 'post',
    })
}