import axios from 'axios'
import store from '../store'
// import { Message, MessageBox } from 'element-ui'
// import { getToken } from './auth'

const service = axios.create({
  baseURL: "http://192.168.200.46:8990",
  // baseURL: "http://192.168.150.174:8085",
  timeout: 5000
})

service.interceptors.request.use(config => {
  if (store.state.token) {
    config.headers['token'] = store.state.token
  } else if (window.sessionStorage.getItem("token")) {
    config.headers['token'] = window.sessionStorage.getItem("token")
  }
  return config
}, error => {
  // Do something with request error
  console.log(error) // for debug
  Promise.reject(error)
})

// respone拦截器
/**
* code为非200是抛错 可结合自己业务进行修改
*/

service.interceptors.response.use(response => {
  const resp = response.data
  if (resp.code === 200) {
    return response.data;
  } else {
    return Promise.reject(response.data)
  }
}, error => {
  return error;
})


export default service