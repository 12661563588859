<template>
  <el-container>
    <el-header><h1>自然语言采集系统</h1></el-header>
    <el-main>
      <el-form
        style="width: 80%; margin: auto"
        ref="form"
        :model="loginData"
        label-width="180px"
      >
        <el-form-item label="请输入您的姓名">
          <el-input v-model="loginData.userName"></el-input>
        </el-form-item>
        <el-form-item label="活动区域">
          <el-select v-model="loginData.projectId" placeholder="请选择采集项目">
            <el-option
              v-for="project in projectList"
              :key="project.id"
              :value="project.id"
              :label="project.projectName"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="onSubmit">开始采集</el-button>
        </el-form-item>
      </el-form>
    </el-main>
  </el-container>
</template>
<script>
import { getProject, getProjectList } from "@/network/login.js";

export default {
  data() {
    return {
      loginData: {
        userName: "",
        projectId: "",
      },
      projectList: 0,
    };
  },
  created() {
    getProjectList().then((res) => {
      this.projectList = res.data;
    });
    localStorage.removeItem("sort");
    localStorage.removeItem("projectId");
    // this.setProjectName();
  },
  methods: {
    onSubmit() {
      if (this.loginData.userName == "") {
        this.$message({ message: "请输入您的名字哦", type: "warning" });
        return "";
      }
      localStorage.setItem("projectId", this.loginData.projectId);
      this.$store.dispatch("Login", this.loginData).then(() => {
        this.$router.push({ path: "/collect" });
      });
    },
    setProjectName() {
      getProject(this.project_id).then((res) => {
        this.project_name = res.data.projectName;
      });
    },
  },
};
</script>