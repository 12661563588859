import Vue from 'vue'
import Vuex from 'vuex'
import { getToken } from '@/utils/auth'
import { login } from "@/network/login.js"

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    token: getToken(),
  },
  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
  },
  actions: {
    Login({ commit }, userInfo) {
      return new Promise((resolve, reject) => {
        login(userInfo.userName).then(response => {
          const token = response.data
          window.sessionStorage.setItem("token", token)
          commit('SET_TOKEN', token)
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    }
  },
  modules: {
  }
})
